import { ChangeEvent, FunctionComponent, useState } from 'react';
import classnames from 'classnames';

import InputWrapper, { ValidationActions } from 'components/layout/forms/InputWrapper';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutFormsInput: FunctionComponent<Props> = ({
    formik,
    name,
    label,
    helper,
    value,
    onChange,
    style,
    styles,
    disabled,
    validationAction,
    decoratorLeft,
    decoratorRight,
    expandable,
    error,
}) => {
    const errorMessage = formik?.errors[name]?.toString() || error;
    const [isNotExpanded, setIsNotExpanded]: [boolean, Function] = useState(true);

    return (
        <StyledComponent
            className={classnames(
                'layout-forms-checkbox',
                [`style-${style}`],
                {
                    'error': Boolean(errorMessage),
                    'decorator-left': Boolean(
                        errorMessage && validationAction === ValidationActions.Decorator ||
                        decoratorLeft && decoratorLeft.visible !== false
                    ),
                    'decorator-right': Boolean(decoratorRight && decoratorRight.visible !== false),
                }
            )}
            style={styles}
        >
            <InputWrapper
                name={name}
                helper={helper}
                error={errorMessage}
                validationAction={ValidationActions.None}
                decoratorLeft={decoratorLeft}
                decoratorRight={decoratorRight}
            >
                <label
                    htmlFor={name}
                    className="checkbox-wrapper"
                >
                    <input
                        id={name}
                        name={name}
                        className="checkbox"
                        onChange={(e: ChangeEvent) => {
                            formik?.setFieldError(name, null);
                            onChange(e);
                        }}
                        type="checkbox"
                        value={value?.toString()}
                        checked={Boolean(value)}
                        disabled={disabled}
                    />
                    <span className="checkbox-trigger" />
                    <span
                        className={classnames(
                            'label',
                            {
                                'checked': Boolean(value),
                            }
                        )}
                    >
                        {/* {expandable
                            ? (<>
                                <span dangerouslySetInnerHTML={{ __html: currentLabel }} />
                                {!isExpanded && (
                                    <span
                                        className="expand-trigger"
                                        onClick={() => {
                                            setCurrentLabel(label);
                                            setIsExpanded(true);
                                        }}
                                    >
                                        {'...pokaż więcej'}
                                    </span>
                                )}
                                {isExpanded && (
                                    <span
                                        className="expand-trigger"
                                        onClick={() => {
                                            setCurrentLabel(shorten(label, 65, false));
                                            setIsExpanded(false);
                                        }}
                                    >
                                        {'...pokaż mniej'}
                                    </span>
                                )}
                            </>)
                            : <span dangerouslySetInnerHTML={{ __html: label }} />
                        } */}
                        {expandable ? (
                            <span
                                dangerouslySetInnerHTML={{ __html: `<div>${label}</div>` }}
                                className={classnames(
                                    'label-wrapper',
                                    { 'expanded': isNotExpanded }
                                )}
                            />
                        ) : <span dangerouslySetInnerHTML={{ __html: `<div>${label}</div>` }} />}
                    </span>
                </label>
                {expandable && (
                    <span
                        className='label-expanded-button'
                        onClick={() => setIsNotExpanded(!isNotExpanded)}
                    >
                        {isNotExpanded
                            ? 'Pokaż więcej'
                            : 'Pokaż mniej'
                        }
                    </span>
                )}
            </InputWrapper>
            {errorMessage && (
                <div className="error-container">
                    <img
                        className="error-icon"
                        src="/images/icons/error.svg"
                        alt="error-icon"
                        loading="lazy"
                    />
                    <div
                        className="error-message"
                        dangerouslySetInnerHTML={{ __html: errorMessage }}
                    />
                </div>
            )}
        </StyledComponent>
    );
};

export default LayoutFormsInput;