import ApiUserAgree from 'types/api/UserAgree';

import Model from 'models/Model';

export enum Types {
    Regulations = 'regulations',
    DataProcessing = 'dataProcessing',
    EmailMarketing = 'emailMarketing',
    PhoneMarketing = 'phoneMarketing',
    EmailMarketingClients = 'emailMarketingClients',
    PhoneMarketingClients = 'phoneMarketingClients',
}
export default class UserAgree implements Model {
    id: string;
    type: Types;
    value: boolean;
    content: string;

    constructor(data: ApiUserAgree) {
        this.id = data.id;
        this.type = data.type;
        this.value = Boolean(data.value);
        this.content = data.content;
    }
}