import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;
	.checkbox-wrapper {
		position: relative;
		width: 100%;
		text-align: left;
		cursor: pointer;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.checkbox-trigger {
			width: 1em;
			height: 1em;
			background-color: #ffffff;
			display: inline-block;
			cursor: pointer;
			position: relative;
			border: 0.01em solid #000000;
			border-radius: none;
		}
		.checkbox {
			position: absolute;
			z-index: -1;
			width: 1px;
			height: 1px;
			margin: 0;
			opacity: 0.01;
			&:checked + .checkbox-trigger,
			&[value='true'] + .checkbox-trigger {
				&:after {
					content: '';
					display: inline-block;
					position: absolute;
					background: black;
					top: 25%;
					left: 25%;
					width: 50%;
					height: 50%;
				}
			}
		}
	}
	.label {
		display: inline-block;
		padding-left: 0.9em;
		text-align: left;
		line-height: 120%;
		font-size: 0.8em;
		color: #121212;
		max-width: 90%;
		margin-top: -0.2em;
		a {
			text-decoration: underline;
			color: #ffffff;
		}
		.expand-trigger {
			cursor: pointer;
			margin-left: 0.5em;
			&:hover {
				text-decoration: underline;
			}
		}
		&.checked {
			font-weight: 300;
		}
		.label-wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			.checkbox-link {
				color: inherit;
			}
			&.expanded {
				max-height: 2.4em;
				overflow: hidden;
			}
		}
	}
	.label-expanded-button {
		text-align: left;
		line-height: 120%;
		font-size: 0.8em;
		color: #121212;
		width: 30%;
		cursor: pointer;
		display: block;

		&:hover {
			text-decoration: underline;
		}
	}
	.error-container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-bottom: 0.5em;
		.error-icon {
			margin-right: 0.5em;
			max-width: 100%;
		}
		.error-message {
			font-size: 0.8em;
			color: #ff0000;
		}
	}
	&.style-secondary {
		.label {
			span {
				color: #ffffff;
			}
		}
	}

	@media all and (max-width: ${vars.mobileM}) {
		.label-expanded-button {
            margin-top: 1em;
			width: 100%;
		}
	}
`;
