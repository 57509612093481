import { FunctionComponent, useEffect } from 'react';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { Props } from './index';

const { publicRuntimeConfig } = getConfig();

const LayoutHead: FunctionComponent<Props> = ({ title, titleSuffix, children, description, canonical, image, robots }) => {
    const router = useRouter();
    const path = router.asPath.split('?')[0];

    return (
        <div>
            <Head>
                {/* Seo */}
                <title>{title} {titleSuffix}</title>
                {description && (
                    <meta
                        name="description"
                        content={description}
                    />
                )}
                {canonical && (
                    <link
                        rel='canonical'
                        href={publicRuntimeConfig.APP_URL + path}
                    />
                )}
                {publicRuntimeConfig.APP_ENV !== 'production' && (
                    <meta
                        name="robots"
                        content="noindex,nofollow"
                    />
                )}
                {robots && (
                    <meta
                        name='robots'
                        content={robots}
                    />
                )}
                {/* Favicon */}
                <link
                    rel="apple-touch-icon"
                    sizes="57x57"
                    href="/static/favicon/apple-icon-57x57.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="60x60"
                    href="/static/favicon/apple-icon-60x60.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="72x72"
                    href="/static/favicon/apple-icon-72x72.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="76x76"
                    href="/static/favicon/apple-icon-76x76.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="114x114"
                    href="/static/favicon/apple-icon-114x114.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="120x120"
                    href="/static/favicon/apple-icon-120x120.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="144x144"
                    href="/static/favicon/apple-icon-144x144.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href="/static/favicon/apple-icon-152x152.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/static/favicon/apple-icon-180x180.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="192x192"
                    href="/static/favicon/android-icon-192x192.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/static/favicon/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="96x96"
                    href="/static/favicon/favicon-96x96.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/static/favicon/favicon-16x16.png"
                />
                <link
                    rel="icon"
                    href="/static/favicon/favicon.ico"
                />
                <link
                    rel="manifest"
                    href="/static/favicon/manifest.json"
                />
                <meta
                    name="msapplication-TileColor"
                    content="#ffffff"
                />
                <meta
                    name="msapplication-TileImage"
                    content="/static/favicon/ms-icon-144x144.png"
                />
                <meta
                    name="theme-color"
                    content="#ffffff"
                />

                {/* openGraph */}
                <meta
                    property="og:image"
                    content={image || publicRuntimeConfig.APP_URL + '/images/og-default.jpg'}
                />
                <meta
                    property="og:title"
                    content={title}
                />
                {description && (
                    <meta
                        property="og:description"
                        content={description}
                    />
                )}
            </Head>

            {children}
        </div>
    );
};

export default LayoutHead;