import { Component, ReactNode, useEffect } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';

import { Props, State } from './index';
import StyledComponent from './styles';

export default class LayoutFormsForm extends Component<Props> {
    static defaultProps = {
        children: null,
        errorMessage: null,
        enableReinitialize: false,
        initialValues: {},
        innerRef: null,
        onSuccess: (response: object, formikHelpers: FormikHelpers<any>): any => response,
        onError: (error: object): any => error,
        onSubmitData: (values: object): object => values,
        onChange: (formik: object, values: object): any => values,
        submitAction: null,
    };
    state: State = {
        globalErrorMessage: null,
    };

    onSubmit = async (values: object, formikHelpers: FormikHelpers<any>): Promise<any> => {
        const { submitAction, onSubmitData, onSuccess, onError } = this.props;

        try {
            const response = await submitAction({
                ...onSubmitData(values),
            });

            if(!response) {
                console.error('No response', values);
                throw new Error('No response');
            }

            return onSuccess(response, formikHelpers);
        } catch (error) {
            console.error('onSubmit error', error);

            if (Array.isArray(error?.payload?.validationErrors) && Object.keys(error?.payload?.validationErrors).length > 0) {
                formikHelpers.setErrors(error.payload.validationErrors);
            } else {
                this.setState((prevState: State) => ({
                    ...prevState,
                    globalErrorMessage: error?.payload?.message || '',
                }));
            }

            return onError(error, formikHelpers);
        }
    };

    render = (): ReactNode => {
        const { initialValues, enableReinitialize, innerRef, children, errorMessage, onChange } = this.props;
        const { globalErrorMessage } = this.state;

        return (
            <StyledComponent className="layout-forms-form">
                <Formik
                    enableReinitialize={enableReinitialize}
                    initialValues={initialValues}
                    innerRef={innerRef}
                    onSubmit={(values: object, formikHelpers: FormikHelpers<any>) => this.onSubmit(values, formikHelpers)}
                    className="form-container"
                >
                    {(formik: FormikProps<any>) => {
                        const { values } = formik;

                        useEffect(() => {
                            onChange(formik, values);
                        }, [values]);

                        return (
                            <form
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                {children({ formik, errors: {}, errorMessage: errorMessage || globalErrorMessage })}

                                {/* Hack for chrome autocomplete: https://stackoverflow.com/questions/37503656/react-doesnt-render-autocomplete-off */}
                                <input
                                    type="text"
                                    autoComplete="on"
                                    value=""
                                    style={{ display: 'none', opacity: 0, position: 'absolute', left: '-100000px' }}
                                    onChange={() => null}
                                />
                            </form>
                        );
                    }}
                </Formik>
            </StyledComponent>
        );
    };
}
