import { FormikHelpers } from 'formik';

import Component from './component';

export interface Props {
    children: any;
    enableReinitialize?: boolean;
    errorMessage?: string;
    initialValues?: object;
    innerRef?: any;
    submitAction: (values: { [key: string]: any }) => Promise<any>;
    onSubmitData?: (values: any) => object;
    onSuccess?: (response: any, formikHelpers: FormikHelpers<any>) => any;
    onError?: (error: any, formikHelpers: FormikHelpers<any>) => any;
    onChange?: (formik: any, values: any) => any;
}

export interface State {
    globalErrorMessage?: string;
}


export default Component;